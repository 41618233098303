import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class VehicleService {
    readQuery(){
        var query = `query{
            GetMasterVehicle{
                vehicle_id
                vehicle_year
                notes
                license_plate
            }
          }`;
        return query;
    }

    async deleteQuery(variables){
        const query = gql`mutation($id:Int!){
            DeleteMasterVehicle(VehicleID:$id)
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async addQuery(variables){
        const query = gql`mutation($data:NewMasterVehicle!){
            CreateMasterVehicle(New:$data)
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async editQuery(variables){
        const query = gql`mutation($id:Int!,$data:NewMasterVehicle!){
            UpdateMasterVehicle(VehicleID:$id,New:$data)
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}


export default new VehicleService();